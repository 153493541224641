import React from 'react'
import {
  Row,
  Col,
  Container,
  Button
} from 'reactstrap'

import {
  FaFlask,
  FaUsers,
  FaMobileAlt,
  FaMapMarkerAlt,
  FaPaperPlane
} from 'react-icons/fa'

import { Link } from 'react-router-dom'

import {
  Section,
  ColorBlock
} from '../components/Utilities'

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.contactRef = React.createRef()
    this.state = {
      contactInView: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.yVal !== this.props.yVal) {
      var threshold = this.contactRef.current.offsetTop + (this.contactRef.current.clientHeight * 0.5)
      if (this.props.yVal >= threshold) {
        this.setState({
          contactInView: true
        })
      } else {
        this.setState({
          contactInView: false
        })
      }
    }
  }

  render() {
    return (
      <div className='index'>
        <Section header='About the Powers &amp; Brissova Research Group'>
          <Row>
            <Col md='8'>
              <p>Pancreatic islets are highly specialized mini-organs that regulate blood glucose by secreting insulin, glucagon, and other hormones in response to physiologic stimuli such as glucose. A deficiency or a dysfunction of the insulin-producing &beta; cells of the islet is critical to the development of all forms of diabetes.</p>
              <p>The Powers &amp; Brissova group is working to understand and reverse β cell and islet abnormalities and dysfunction in diabetes. In our <Link to='/projects'>projects</Link>, we are studying the pathogenesis of human diabetes by conducting, translating, and integrating studies about the human pancreas and islet biology.</p>
              <p>Our research team of scientists, postdoctoral fellows, graduate students, medical students, undergraduate students, and research assistants has interdisciplinary collaborations with cell biologists, developmental biologists, biomedical engineers, geneticists, and bioinformaticians at Vanderbilt and around the world (Philadelphia, Palo Alto, New York, Alberta, Gainesville, Worcester, Austin, Denver, Chicago, Melbourne, Miami, Dresden, and Bar Harbor). We are part of the NIH-funded <a href='https://hirnetwork.org/'>Human Islet Research Network (HIRN)</a>, the <a href="https://hpap.pmacs.upenn.edu/">Human Pancreas Analysis Program (HPAP)</a>, the <a href="https://portal.hubmapconsortium.org/">Human BioMolecular Atlas Program (HuBMAP)</a>, and the <a href="https://www.map-t1d.com/">Multicenter Assessment of the Pancreas in Type 1 Diabetes (MAP-T1D)</a>.</p>

              <p>The Powers & Brissova Research Group is part of the Diabetes Center, the Department of Medicine, and the <a href="https://medschool.vanderbilt.edu/mpb/">Department of Molecular Physiology and Biophysics</a> at Vanderbilt. Our research is supported by the NIH/NIDDK, the Veterans Affairs (VA) Research program, and The Helmsley Charitable Trust.</p>
              <hr />
              <p className='highlight'>At the May 2023 Vanderbilt University School of Medicine Faculty Assembly (<a href='https://news.vumc.org/2023/05/24/faculty-awards-honor-teaching-clinical-research-excellence-2/' rel='noopener noreferrer' target='_blank'>Faculty Awards</a>), Al Powers and Marcela Brissova received <a href='https://news.vumc.org/2019/08/01/oates-recalled-as-iconic-leader-physician-scientist' rel='noopener noreferrer' target='_blank'>JOHN A. OATES</a>, MD, Award for Faculty Working Collaboratively or in a Multidisciplinary Manner to Address Important Biological Processes and/or Diseases based in their discoveries about the development, vascularization, innervation, function, and dysfunction of pancreatic islets.</p>
              <br/>
              <Row>
              
                <Col md='12'>
                  <Row style={{ marginBottom: '2em' }}>
                    <Col md='6'>
                      <ColorBlock color='#3498DB' minHeight='13em' hoverables={[<Link key='proj-link' to='/projects'><Button color='warning'>Learn More</Button></Link>]}>
                        <FaFlask size='2em' />
                        <h4 className='color-block-header'>PROJECTS</h4>
                      </ColorBlock>
                    </Col>
                    <Col md='6'>
                      <ColorBlock color='#e74c3c' minHeight='13em' hoverables={[<Link key='people-link' to='/people'><Button color='warning'>Learn More</Button></Link>]}>
                        <FaUsers size='2em' />
                        <h4 className='color-block-header'>MEMBERS</h4>
                      </ColorBlock>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='6'>
                      <img alt='Medical Research Building IV' className='img-fluid' src={require('../assets/mrb4.jpg')} />
                    </Col>
                    <Col md='6'>
                      <p>We are located in Nashville, TN, on the beautiful Vanderbilt University campus. Vanderbilt is a private research university and medical center and offers a wealth of research opportunities, resources and community.</p>
                    </Col>
                  </Row>
                </Col>

              </Row>

            </Col>
            <Col md='4'>
              <ColorBlock key='news' color='#3498DB'>
                <h4>News</h4>
                <a href="https://news.vumc.org/2023/12/04/study-links-gene-network-and-pancreatic-beta-cell-defects-to-type-2-diabetes/">
                  <img alt='Study links gene network and pancreatic beta cell defects to early-stage Type 2 diabetes' className='img-fluid' src={require('../assets/news/nature_photo.jpg')}/></a>
                <p>December, 4 2023 - Study links gene network and pancreatic beta cell defects to early-stage Type 2 diabetes. - <a className='white' href="https://news.vumc.org/2023/12/04/study-links-gene-network-and-pancreatic-beta-cell-defects-to-type-2-diabetes/">VUMC Reporter</a>, <a className='white' href="https://directorsblog.nih.gov/2024/01/04/uncovering-disease-driving-events-that-lead-to-type-2-diabetes/">NIH Director’s Blog</a>.</p>
                <hr />
                <a href="https://news.vumc.org/2023/11/01/pancreas-crosstalk-may-influence-course-of-type-1-and-type-2-diabetes/"><img alt='Pancreas “crosstalk” may influence course of Type 1 and Type 2 diabetes' className='img-fluid' src={require('../assets/news/Wright-and-Eskaros-Pancreas.jpg')} /></a>
                <p>November 1, 2023 - Pancreas “crosstalk” may influence course of Type 1 and Type 2 diabetes - <a class="white" href="https://news.vumc.org/2023/11/01/pancreas-crosstalk-may-influence-course-of-type-1-and-type-2-diabetes/">VUMC Reporter</a>.</p>
                <hr />
                

                
                

                
                <p><a class="white" href="/news">View more news</a></p>
              </ColorBlock>
            </Col>
          </Row>
        </Section>
        <Container fluid style={{ backgroundColor: '#b6d96a' }}>
          <Container style={{ minHeight: '13em' }}>
            <Row style={{ minHeight: '13em' }}>
              <Col className='d-flex align-content-center justify-content-center flex-wrap' md={2} sm={6} xs={12} >
                <h3>Key Activities</h3>
                <p>We are involved with</p>
              </Col>

              <Col className='d-flex align-content-center justify-content-center flex-wrap' md={2} sm={6} xs={12} style={{ backgroundColor: '#d3e8a6' }}>
                <a href='https://hirnetwork.org' rel='noopener noreferrer' target='_blank'><img alt='HIRN' className='img-fluid' src={require('../assets/HIRN.png')} /></a>
              </Col>

              <Col className='d-flex align-content-center justify-content-center flex-wrap' md={2} sm={6} xs={12}>
                <a href='https://hpap.pmacs.upenn.edu' rel='noopener noreferrer' target='_blank'><img alt='HPAP' className='img-fluid' src={require('../assets/HPAP.png')} /></a>
              </Col>

              <Col className='d-flex align-content-center justify-content-center flex-wrap' md={2} sm={6} xs={12} style={{ backgroundColor: '#d3e8a6' }}>
                <a href='https://www.pancreatlas.org' rel='noopener noreferrer' target='_blank'><img alt='Pancreatlas' className='img-fluid' src={require('../assets/pancreatlas.png')} /></a>
              </Col>

              <Col className='d-flex align-content-center justify-content-center flex-wrap' md={2} sm={6} xs={12}>
                <a href='https://hubmapconsortium.org' rel='noopener noreferrer' target='_blank'><img alt='HubMap' className='img-fluid' src={require('../assets/HuBMAP.png')} /></a>
              </Col>

              <Col className='d-flex align-content-center justify-content-center flex-wrap' md={2} sm={6} xs={12} style={{ backgroundColor: '#d3e8a6' }}>
                <a href='https://www.vumc.org/vdrc/home' rel='noopener noreferrer' target='_blank'><img alt='DRTC' className='img-fluid' src={require('../assets/DRTC.png')} /></a>
              </Col>

            </Row>
          </Container>
        </Container>
        {/* <img className='img-fluid' style={{ minWidth: 'calc(100vw - (100vw - 100%))' }} alt='Vanderbilt' src={require('../assets/Vanderbilt-Aerial-1.jpg')} />
          <div className='contact-content' > */}
        <Container fluid className='w-100 pl-0 pr-0 contact-bg'>
          <div id='contact' ref={this.contactRef} className='vanderbilt-image-background contact-content pt-4' style={{ position: 'relative' }}>
            <Row className='w-100'>
              <Col md={4} xs={12} className='text-center'>
                <div className={this.state.contactInView ? 'contact fly-in-left' : 'contact invisible'}>
                  <p><FaMobileAlt size='3em' /></p>
                  <p>Phone: <a href='tel:615-936-7678'>615-936-7678</a></p>
                </div>
              </Col>
              <Col md={4} xs={12} className='text-center'>
                <div className={this.state.contactInView ? 'contact fly-in-bottom' : 'contact invisible'}>
                  <p><FaMapMarkerAlt size='3em' /></p>
                  <p>7465 Medical Research Building IV</p>
                  <p>2215 Garland Avenue</p>
                  <p>Nashville, TN</p>
                  <p>37232-0475</p>
                </div>
              </Col>
              <Col md={4} xs={12} className='text-center'>
                <div className={this.state.contactInView ? 'w-100 contact fly-in-right' : 'w-100 contact invisible'}>
                  <p><FaPaperPlane size='3em' /></p>
                  <p>Email:</p>
                  <p><a href='mailto:Laia.a.jones@vumc.org?subject=Powers-Brissova Group Website Inquiry'>Laia.a.jones@vumc.org</a></p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        {/* </div> */}
      </div>

    )
  }
}
