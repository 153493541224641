import React from 'react'

import {
  Row,
  Col
} from 'reactstrap'

import {
  Section
} from '../components/Utilities'

import news from '../assets/data/news.json'

export default class News extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      news: news.news
    }
  }
  render () {
    return (
      <div className='news'>
        <Section header='News'>
         {/* <p>News:</p>*/}
          {this.state.news.map((item, index) => {
            return (
              <Row key={index} className='mb-4'>
                <Col md='4'>
                  <a href={item.url}><img alt={item.img_alt} className='img-fluid rounded border' src={require(`../assets/news/${item.img}`)} /></a>
                </Col>
                <Col md='8'>
                  <h4>{item.date}</h4>
                  <p><div dangerouslySetInnerHTML={{ __html: item.desc }} /></p>
                </Col>
              </Row>
            )
          })}
        </Section>
      </div>
    )
  }
}
