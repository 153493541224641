import React from 'react'
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap'

import {
  FaFlask
} from 'react-icons/fa'

import { Link } from 'react-router-dom'

export default class CustomNavbar extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    return (
      <Navbar className={this.props.scrolled ? 'navbar-scrolled' : 'navbar-unscrolled'} color='dark' dark expand='md' fixed='top'>
        <Container>
          <NavbarBrand href='/' style={{maxWidth: '75%'}}><h1 className={this.props.scrolled ? 'navbar-header-scrolled' : 'navbar-header'}>Powers &amp; Brissova Research Group</h1></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <NavItem>
                <Link className='nav-link' to='/' onClick={() => (window.scrollTo(0, 0))}>HOME</Link>
              </NavItem>
              <NavItem>
                <Link className='nav-link' to='/projects' onClick={() => (window.scrollTo(0, 0))}>PROJECTS</Link>
              </NavItem>
              <NavItem>
                <Link className='nav-link' to='/people' onClick={() => (window.scrollTo(0, 0))}>PEOPLE</Link>
              </NavItem>
              <NavItem>
                <Link className='nav-link' to='/collaborators' onClick={() => (window.scrollTo(0, 0))}>COLLABORATORS</Link>
              </NavItem>
              <NavItem>
                <NavLink href='/#contact'>CONTACT US</NavLink>
              </NavItem>
              <NavItem>
                <Link className='nav-link' to='/support' onClick={() => (window.scrollTo(0, 0))}>SUPPORT</Link>
              </NavItem>
              <NavItem>
                <Link className='nav-link' to='/positions' onClick={() => (window.scrollTo(0, 0))}>WORK WITH US <FaFlask /></Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>

    )
  }
}
