import React from 'react'

import {
  Row,
  Col
} from 'reactstrap'

import {
  Section,
  PersonInfo
} from '../components/Utilities'


import 'firebase/auth'
import 'firebase/firestore'

import firebase from '../components/Firestore'

export default class People extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      people: [],
      pis: [],
      loaded: false
    }
  }

  componentDidMount () {
    firebase.auth().signInAnonymously().then(() => {
      var db = firebase.firestore()
      var members = []
      var tmp = []
      var atmp=[]
      var pis = []
      var alum = []
      db.collection('members').orderBy('lname').get().then((snapshot) => {
        snapshot.forEach((doc) => {
          if(doc.data().alumni === true){
            atmp.push(doc.data())
            if (atmp.length === 2) {
              alum.push(atmp)
              atmp = []
            }
            }
          
          if (doc.data().role === 'PI') {
            if (doc.data().lname === 'Powers') {
              pis[0] = doc.data()
            } else {
              pis[1] = doc.data()
            }
          } else {
            if(doc.data().alumni === false){
            tmp.push(doc.data())
            if (tmp.length === 2) {
              members.push(tmp)
              tmp = []
            }
          }
          }
        })
        
        if (tmp.length > 0) {
          members.push(tmp)
        }
        
        this.setState({
          pis: pis,
          people: members,
          alumni:alum,
          loaded: true
        })
      })
      
      
    }).catch(error => {
      console.error(error)
    })
    
  }

  render () {
    if (this.state.loaded) {
      return (
        <div className='people'>
          <Section header='People'>
            <Row className='border-bottom mb-4'>
              {this.state.pis.map(pi => {
                return (
                  <Col md='6' key={`${pi.fname}-${pi.lname}`}>
                    <PersonInfo size='lg' person={pi} />
                  </Col>

                )
              })}
            </Row>
            {this.state.people.slice(0, this.state.people.length - 1).map(pair => {
              return (
                <Row className='border-bottom mb-4'>
                  {pair.map((member, index) => {
                    return (
                      <Col key={index} md='6'>
                        <PersonInfo size='sm' person={member} />
                      </Col>
                    )
                  })}
                </Row>
              )
            })}
            <Row className='mb-4'>
              {this.state.people[this.state.people.length - 1].map((member, index) => {
                return (
                  <Col key={index} md='6'>
                    <PersonInfo size='sm' person={member} />
                  </Col>
                )
              })}
            </Row>
          </Section>
        </div>
      )
    } else {
      return null
    }
  }
}
