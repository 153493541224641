import React from 'react'

import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

import CustomNavbar from './components/CustomNavbar'
import CustomFooter from './components/CustomFooter'

import Index from './pages/Index'
import People from './pages/People'
import Projects from './pages/Projects'
import Collaborators from './pages/Collaborators'
import News from './pages/News'
import Support from './pages/Support'
import WorkWithUs from './pages/WorkWithUs'

import ReactGA from 'react-ga'
import CarouselHeader from './components/CarouselHeader'

export default class App extends React.Component {
  constructor (props) {
    super(props)
    this.listenToScroll = this.listenToScroll.bind(this)

    this.state = {
      yPos: window.pageYOffset,
      yVal: window.pageYOffset + window.innerHeight
    }
  }

  componentDidMount () {
    ReactGA.initialize('UA-140311900-2')
    window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll () {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const scrolled = winScroll / height

    this.setState({
      yPos: scrolled,
      yVal: window.pageYOffset + window.innerHeight
    })
  }

  render () {
    return (
      <div className='App'>
        <Router>
          <CustomNavbar scrolled={this.state.yPos > 0 || window.innerWidth <= 760} />
          <CarouselHeader large={window.location.pathname === '/'} />
          <Route path='/' exact render={(props) => <Index {...props} yVal={this.state.yVal} />} />
          <Route path='/people' component={People} />
          <Route path='/projects' component={Projects} />
          <Route path='/collaborators' component={Collaborators} />
          <Route path='/news' component={News} />
          <Route path='/support' component={Support} />
          <Route path='/positions' component={WorkWithUs} />
          <CustomFooter />
        </Router>
      </div>
    )
  }
}
