import React from 'react'

import {
  Row,
  Col,
  Button
} from 'reactstrap'

import {
  Section
} from '../components/Utilities'

export default class WorkWithUs extends React.Component {
  render () {
    return (
      <div className='work-with-us'>
        <Section main header='Work with Us at the Powers &amp; Brissova Research Group'>
          <Row>
            <Col sm='12'>
              <p>
                The Powers and Brissova research groups in the <a href='https://www.vumc.org/diabetescenter/DRTC'>Vanderbilt Diabetes Center</a> are seeking postdoctoral fellows to study pancreatic islet biology with a focus on the molecular aspects of gene
                expression, developmental biology, computational biology, and multiplplex imaging of islets, especially in the human islet and pancreas. Our goal is to discover the fundamental molecular
                mechanisms of all forms of human diabetes, leading to the development of new therapies and/or prevention of diabetes. Our collaborative, diabetes-related research is part of the <a href='https://hirnetwork.org/'>Human islet Research Network</a>.
              </p>
              <a href='mailto:laia.a.jones@vumc.org?subject=Powers-Brissova Group Website Inquiry'><Button color='success'>Please send CV</Button></a>
            </Col>
          </Row>
        </Section>
      </div>
    )
  }
}
