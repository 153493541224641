import firebase from 'firebase/app'
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: 'AIzaSyCB9VIWk0zfahIlK4AI0nqfvNqwYCXPHJw',
  authDomain: 'powersbrissovaresearch.firebaseapp.com',
  databaseURL: 'https://powersbrissovaresearch.firebaseio.com',
  projectId: 'powersbrissovaresearch',
  storageBucket: 'gs://powersbrissovaresearch.appspot.com',
  messagingSenderId: '791887107278',
  appId: '1:791887107278:web:06e7d64677fa34c0'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.auth().signInAnonymously()

export default firebase
