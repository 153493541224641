import React from 'react'

import {
  Row,
  Col
} from 'reactstrap'

import {
  Section,
  CollaboratorInfo
} from '../components/Utilities'

// import {
//   Map,
//   TileLayer,
//   Marker,
//   Popup
// } from 'react-leaflet'

import L from 'leaflet'

import 'firebase/auth'
import 'firebase/firestore'

import firebase from '../components/Firestore'

export const VUMCIcon = new L.Icon({
  iconUrl: require('../assets/leaflet_icons/marker-vumc.png'),
  iconRetinaUrl: require('../assets/leaflet_icons/marker-vumc.png'),
  popupAnchor: [0, 0],
  iconSize: [50, 50],
})

export const AHNIcon = new L.Icon({
  iconUrl: require('../assets/leaflet_icons/marker-ahn.png'),
  iconRetinaUrl: require('../assets/leaflet_icons/marker-ahn.png'),
  popupAnchor: [0, 0],
  iconSize: [50, 50],
})

export const IcahnIcon = new L.Icon({
  iconUrl: require('../assets/leaflet_icons/marker-icahn.png'),
  iconRetinaUrl: require('../assets/leaflet_icons/marker-icahn.png'),
  popupAnchor: [0, 0],
  iconSize: [50, 50],
})

export const NPODIcon = new L.Icon({
  iconUrl: require('../assets/leaflet_icons/marker-npod.png'),
  iconRetinaUrl: require('../assets/leaflet_icons/marker-npod.png'),
  iconAnchor: [50, 0],
  popupAnchor: [0, 0],
  iconSize: [50, 50],
})

export const UFIcon = new L.Icon({
  iconUrl: require('../assets/leaflet_icons/marker-uf.png'),
  iconRetinaUrl: require('../assets/leaflet_icons/marker-uf.png'),
  iconAnchor: [0, 0],
  popupAnchor: [0, 0],
  iconSize: [50, 50],
})

export const StanfordIcon = new L.Icon({
  iconUrl: require('../assets/leaflet_icons/marker-stanford.png'),
  iconRetinaUrl: require('../assets/leaflet_icons/marker-stanford.png'),
  popupAnchor: [0, 0],
  iconSize: [50, 50],
})

export function generateIcon(iconUrl, iconAnchor=undefined, iconSize=[50, 50]) {
  return new L.Icon({
    iconUrl: iconUrl,
    iconRetinaUrl: iconUrl,
    iconAnchor: iconAnchor,
    popupAnchor: [0, 0],
    iconSize: iconSize
  })
}

export default class Collaborators extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collabs: [],
      loaded: false
    }
  }

  componentDidMount() {
    firebase.auth().signInAnonymously().then(() => {
      var db = firebase.firestore()
      var collabs = []
      db.collection('collaborators').where('active', '==', true).orderBy('lname').get().then((snapshot) => {
        snapshot.forEach((doc) => {
          collabs.push(doc.data())
        })
        this.setState({
          collabs: collabs,
          loaded: true
        })
      })
    }).catch(error => {
      console.error(error)
    })
  }

  render() {
    // const vumcPosition = [36.142285, -86.802410]
    if (this.state.loaded) {
      return (
        <div className='collabs'>
          <Section header='Collaborators'>
            <Row>
              <Col xs={12} md={12}>
                {this.state.collabs.map(collab => {
                  return (
                    <Row className='border-bottom mb-4'>
                      <CollaboratorInfo collaborator={collab} />
                    </Row>
                  )
                })}
              </Col>
              {/* <Col xs={12} md={7}>
                <Map center={[39.8283, -98.5795]} zoom={4}>
                  <TileLayer
                    attribution='Imagery from <a href="http://giscience.uni-hd.de/">GIScience Research Group @ University of Heidelberg</a> | Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://api.maptiler.com/maps/darkmatter/{z}/{x}/{y}.png?key=uAvY4nSeIuuKMffPxon3"
                  />
                  <Marker position={vumcPosition} icon={generateIcon(require('../assets/leaflet_icons/marker-vumc.png'))}>
                    <Popup>
                      Vanderbilt University Medical Center
                    </Popup>
                  </Marker>
                  <Marker position={[29.644019, -82.354748]} icon={generateIcon(require('../assets/leaflet_icons/marker-uf.png'), [0, 0])}>
                    <Popup>
                      University of Florida
                    </Popup>
                  </Marker>
                  <Marker position={[29.644019, -82.35748]} icon={generateIcon(require('../assets/leaflet_icons/marker-npod.png'), [50, 0])}>
                    <Popup>
                      nPOD
                    </Popup>
                  </Marker>
                  <Marker position={[40.442525, -80.002531]} icon={generateIcon(require('../assets/leaflet_icons/marker-ahn.png'))}>
                    <Popup>
                      Allegheny Health Network
                    </Popup>
                  </Marker>
                  <Marker position={[40.7128, -74.0060]} icon={generateIcon(require('../assets/leaflet_icons/marker-icahn.png'))}>
                    <Popup>
                      Icahn School of Medicine at Mt. Sinai
                    </Popup>
                  </Marker>
                  <Marker position={[37.4275, -122.1697]} icon={generateIcon(require('../assets/leaflet_icons/marker-stanford.png'))}>
                    <Popup>
                      Stanford University
                    </Popup>
                  </Marker>
                </Map>
              </Col> */}
            </Row>
          </Section>
        </div>
      )
    } else {
      return null
    }
  }
}
