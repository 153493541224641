import React from 'react'

import {
  Row,
  Col
} from 'reactstrap'

import {
  Section
} from '../components/Utilities'

import support from '../assets/data/support.json'

export default class Support extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      support: support.support
    }
  }
  render () {
    return (
      <div className='support'>
        <Section header='Support Organizations'>
          <p>Our research is generously supported by or assisted by our association with the following organizations:</p>
          {this.state.support.map((supporter, index) => {
            return (
              <Row key={index} className='mb-4'>
                <Col md='4'>
                  <a href={supporter.url}><img alt={supporter.name} className='img-fluid rounded border' src={require(`../assets/support/${supporter.img}`)} /></a>
                </Col>
                <Col md='8'>
                  <h4>{supporter.name}</h4>
                </Col>
              </Row>
            )
          })}
        </Section>
      </div>
    )
  }
}
