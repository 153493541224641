import React from 'react'
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'

import {
  FaEnvelope,
  FaLinkedin,
  FaGlobe
} from 'react-icons/fa'

import ReactCountryFlag from 'react-country-flag'

import firebase from './Firestore'

export class Section extends React.Component {
  render () {
    return (
      <div className={`w-100 section ${this.props.main ? 'main' : ''}`}>
        <Container>
          <Row style={{ marginBottom: '2em' }}>
            <Col xs='12'>
              <h3>
                <span className='section-header'>{this.props.header}</span>
              </h3>
            </Col>
          </Row>
          {this.props.children}
        </Container>
      </div>
    )
  }
}

export class ColorBlock extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hover: false
    }

    this.handleHover = this.handleHover.bind(this)
  }

  handleHover () {
    this.setState(prevState => ({
      hover: !prevState.hover
    }))
  }

  render () {
    return (
      <div className='color-block' style={{ backgroundColor: this.props.color, height: this.props.minHeight !== undefined ? this.props.minHeight : 'auto' }} onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
        {this.props.children}
        <AnimationElement style={{ flexGrow: 1 }} visible={this.state.hover}>
          {this.props.hoverables}
        </AnimationElement>
      </div>
    )
  }
}

export class AnimationElement extends React.Component {
  render () {
    return (
      <div className='d-flex flex-column justify-content-center animation-element' style={{ flexGrow: 1 }}>
        <div className={`object movable ${this.props.visible ? 'move-right-activated' : 'move-right'}`}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export class Header extends React.Component {
  render () {
    return (
      <Container fluid style={{ backgroundColor: this.props.bgColor }}>
        <Container style={{ minHeight: '16em' }}>
          {this.props.children}
        </Container>
      </Container>
    )
  }
}

export class PersonInfo extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      imgURL: 'https://placehold.it/300x400'
    }
  }

  componentDidMount () {
    firebase.auth().signInAnonymously().catch(error => {
      console.error(error)
    })
    var ref = firebase.storage().ref().child(this.props.person.photo)
    ref.getDownloadURL().then((url) => {
      this.setState({
        imgURL: url
      })
    })
  }
  render () {
    if (this.props.size === 'sm') {
      if (this.props.person.alumni === false){
      return (
        <Container>
          <Row>
            <Col md='4' >
              <Row className='no-gutters'>
                <Col sm='12'>
                  <img alt={`${this.props.person.fname} ${this.props.person.lname}`} className='img-fluid' src={this.state.imgURL} />
                </Col>
              </Row>
              <Row className='no-gutters'>
                <Col md='3'>
                  <ReactCountryFlag style={{ width: '2.333em', height: '2.333em' }} countryCode={this.props.person.country_flag} svg />
                </Col>
                <Col md='5' className='ml-1 d-flex align-items-center'>
                  <p className='hometown'>From {this.props.person.hometown}</p>
                </Col>
                <Col md='3'>
                  <a href={`mailto:${this.props.person.email}`}><FaEnvelope size='2em' /></a>
                  {this.props.person.linkedin !== '' && <a href={this.props.person.linkedin}><FaLinkedin size='2em' /></a>}
                </Col>
              </Row>
            </Col>
            <Col md='8'>
              {this.props.person.degree !== '' && <h4>{`${this.props.person.fname} ${this.props.person.lname}, ${this.props.person.degree}`}</h4>}
              {this.props.person.degree === '' && <h4>{`${this.props.person.fname} ${this.props.person.lname}`}</h4>}
              {this.props.person.title.map((title, index) => (<p key={index}><strong>{title}</strong></p>))}
              {this.props.person.education !== '' && <p><strong>Education</strong></p>}
              {this.props.person.education.map((deg, index) => (<p key={index} className='education-line'>{deg}</p>))}
              {/* <p>{this.props.person.education.split('<br>')}</p> */}
              {/* <div dangerouslySetInnerHTML={{ __html: this.props.person.description }} /> */}
              <ReadMore content={this.props.person.description} />
            </Col>
          </Row>
        </Container>
      )}else{
        return(<Container>
          <Row>
            <Col md='4' >
              <Row className='no-gutters'>
                <Col sm='12'>
                  <img alt={`${this.props.person.fname} ${this.props.person.lname}`} className='img-fluid' src={this.state.imgURL} />
                </Col>
              </Row>
              <Row className='no-gutters'>
                <Col md='3'>
                  <ReactCountryFlag style={{ width: '2.333em', height: '2.333em' }} countryCode={this.props.person.country_flag} svg />
                </Col>
                <Col md='5' className='ml-1 d-flex align-items-center'>
                  <p className='hometown'>From {this.props.person.hometown}</p>
                </Col>
              </Row>
            </Col>
            <Col md='8'>
              {this.props.person.degree !== '' && <h4>{`${this.props.person.fname} ${this.props.person.lname}, ${this.props.person.degree}`}</h4>}
              {this.props.person.degree === '' && <h4>{`${this.props.person.fname} ${this.props.person.lname}`}</h4>}
            </Col>
          </Row>
        </Container>)
      }
    } else {
      return (
        <Container>
          <Row>
            <Col md='6' >
              <Row className='no-gutters'>
                <Col sm='12'>
                  <img className='img-fluid' src={this.state.imgURL} alt='' />
                </Col>
              </Row>
              <Row className='no-gutters'>
                <Col md='3'>
                  <ReactCountryFlag style={{ width: '2.333em', height: '2.333em' }} countryCode={this.props.person.country_flag} svg />
                </Col>
                <Col md='5' className='ml-1 d-flex align-items-center'>
                  <p className='hometown'>From {this.props.person.hometown}</p>
                </Col>
                <Col md='3'>
                  <a href={`mailto:${this.props.person.email}`}><FaEnvelope size='2em' /></a>
                  {this.props.person.linkedin !== '' && <a href={this.props.person.linkedin}><FaLinkedin size='2em' /></a>}
                </Col>
              </Row>
            </Col>
            <Col md='6'>
              <h4>{`${this.props.person.fname} ${this.props.person.lname}, ${this.props.person.degree}`}</h4>
              {this.props.person.title.map((title, index) => (<p key={index}><strong>{title}</strong></p>))}
              {/* <p>{this.props.person.education.split('<br>')}</p> */}

            </Col>
          </Row>
          <Row>
            <Col sm='12'>
              <div dangerouslySetInnerHTML={{ __html: this.props.person.description }} />
              {/* <ReadMore content={this.props.person.description} /> */}
            </Col>
          </Row>
        </Container>
      )
    }
  }
}

export class CollaboratorInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imgURL: 'https://placehold.it/300x400'
    }
  }

  componentDidMount () {
    firebase.auth().signInAnonymously().catch(error => {
      console.error(error)
    })
    var ref = firebase.storage().ref().child(this.props.collaborator.image_path)
    ref.getDownloadURL().then((url) => {
      this.setState({
        imgURL: url
      })
    })
  }
  render () {
      return (
        <Container>
          <Row>
            <Col md='3' >
              <Row className='no-gutters'>
                <Col sm='12'>
                  <a href={this.props.collaborator.institution_link} rel='noopener noreferrer' target='_blank'><img alt={`${this.props.collaborator.fname} ${this.props.collaborator.lname}`} className='img-fluid img-collab' src={this.state.imgURL} /></a>
                </Col>
              </Row>
            </Col>
            <Col md='9'>
              <h4>{`${this.props.collaborator.fname} ${this.props.collaborator.lname}, ${this.props.collaborator.degree}`}<br /><small className='text-muted'>{`${this.props.collaborator.institution}`}</small></h4>
              <div><a href={this.props.collaborator.institution_link} rel='noopener noreferrer' target='_blank'><FaGlobe size='2em' /></a></div>
              {/* <p>{this.props.person.education.split('<br>')}</p> */}
              {/* <div dangerouslySetInnerHTML={{ __html: this.props.person.description }} /> */}
            </Col>
          </Row>
        </Container>
      )
    }
}

export class ReadMore extends React.Component {
  constructor (props) {
    super(props)
    var regex = /<p>.*?<\/p>/g
    var matches; var paragraphs = []
    var shortText = this.props.content
    var showFull = true
    var toggleNeeded = false

    while ((matches = regex.exec(this.props.content))) {
      paragraphs.push(matches[0])
    }
    if (paragraphs.length > 2) {
      shortText = paragraphs.slice(0, 1).join('')
      showFull = false
      toggleNeeded = true
    }

    this.toggleText = this.toggleText.bind(this)

    this.state = {
      fullText: this.props.content,
      shortText: shortText,
      showFull: showFull,
      toggleNeeded: toggleNeeded
    }
  }

  toggleText () {
    if (this.state.showFull) {
      this.setState({
        showFull: false
      })
    } else {
      this.setState({
        showFull: true
      })
    }
  }

  render () {
    if (this.state.toggleNeeded) {
      if (this.state.showFull) {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: this.state.fullText }} />
            <Button className='mb-2' outline color='secondary' size='sm' onClick={this.toggleText}>Show Less</Button>
          </div>
        )
      } else {
        return (
          <div>
            <div dangerouslySetInnerHTML={{ __html: this.state.shortText }} />
            <Button outline color='secondary' size='sm' onClick={this.toggleText}>Show More</Button>
          </div>
        )
      }
    } else {
      return (
        <div dangerouslySetInnerHTML={{ __html: this.state.fullText }} />
      )
    }
  }
}

export class ProjectInfo extends React.Component {
  render () {
    return (
      <Container>
        <Row>
          <Col sm='12'>
            <h4>{this.props.project.title}</h4>
          </Col>
        </Row>
        <Row>
          <Col md='4'>
            <img alt='' className='img-fluid' src={require(`../assets/projects/${this.props.project.img}`)} />
          </Col>
          <Col md='8'>
            <p>{this.props.project.description}</p>
            <ul>
              {this.props.project.citations.map((citation, index) => {
                return (
                  <li key={index} className='citation' dangerouslySetInnerHTML={{ __html: citation }} />
                )
              })}
            </ul>
          </Col>
        </Row>
      </Container>
    )
  }
}
