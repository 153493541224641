import React from 'react'

import {
  Row,
  Col
} from 'reactstrap'

import {
  Section,
  ProjectInfo
} from '../components/Utilities'

import projects from '../assets/data/projects.json'

export default class Projects extends React.Component {
  constructor (props) {
    super(props)

    let tableOfContents = projects.projects.map(project => {
      return { title: project.title, slug: project.slug }
    })

    this.state = {
      projects: projects.projects,
      toc: tableOfContents
    }
  }

  render () {
    return (
      <div className='projects'>
        <Section header='Projects in the Powers &amp; Brissova Research Group'>
          <Row>
            <Col md='12'>
              <h4><small className='text-muted'>The Powers &amp; Brissova Research group seeks to understand and reverse &beta; cell and islet abnormalities and dysfunction in type 1 and type 2 diabetes and is working in these areas:</small></h4>
            </Col>
          </Row>
          <ul>
            {this.state.toc.map(project => {
              return (
                <li key={project.slug}><a href={`#${project.slug}`}>{project.title}</a></li>
              )
            })}
          </ul>
          {this.state.projects.map(project => {
            return (
              <Row key={project.slug} id={project.slug}>
                <ProjectInfo project={project} />
              </Row>
            )
          })}
        </Section>
      </div>
    )
  }
}
