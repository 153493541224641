import React from 'react'
import {
  Carousel,
  CarouselItem,
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'

import { Link } from 'react-router-dom'

import { withRouter } from 'react-router'

import 'firebase/auth'
import 'firebase/storage'

import firebase from './Firestore'

class CarouselHeader extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      activeIndex: 0,
      loaded: false,
      large: this.props.location.pathname === '/'
    }

    this.init = this.init.bind(this)

    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.onExiting = this.onExiting.bind(this)
    this.onExited = this.onExited.bind(this)
  }

  componentDidMount () {
    firebase.auth().signInAnonymously().then(() => {
      var storage = firebase.storage().ref()
      var carouselImgs = storage.child('carousel_images')
      carouselImgs.listAll().then((res) => {
        this.setState({
          items: res.items.map(item => item.fullPath)
        })
        this.init()
      })
    }).catch(error => {
      console.error(error)
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        large: this.props.location.pathname === '/'
      })
    }
  }

  init () {
    var indices = []
    var storage = firebase.storage().ref()
    for (var i = 0; i < 3; i++) {
      indices.push(parseInt(Math.random() * this.state.items.length))
    }
    var imgs = []
    var promises = []
    indices.forEach((idx) => {
      let img = this.state.items[idx]
      promises.push(new Promise(function (resolve, reject) {
        storage.child(img).getDownloadURL().then(function (url) {
          imgs.push({ src: url, altText: 'carousel slide', caption: 'carousel slide' })
          resolve('done')
        })
      }))
    })
    Promise.all(promises).then(() => {
      this.setState({
        carouselImgs: imgs,
        loaded: true
      })
    })
  }

  onExiting () {
    this.animating = true
  }

  onExited () {
    this.animating = false
  }

  next () {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === this.state.carouselImgs.length - 1 ? 0 : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous () {
    if (this.animating) return
    const nextIndex = this.state.activeIndex === 0 ? this.state.carouselImgs.length - 1 : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex (newIndex) {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }

  render () {
    if (this.state.loaded) {
      if (this.state.large) {
        const { activeIndex } = this.state
        const slides = this.state.carouselImgs.map((item) => {
          return (
            <CarouselItem
              onExiting={this.onExiting}
              onExited={this.onExited}
              key={item.src}
            >
              <div className='carousel-image-outer'>
                <img className='carousel-image' src={item.src} alt={item.altText} />
                <div className='overlay' />
              </div>
            </CarouselItem>
          )
        })

        return (
          <div className='carousel-wrapper'>
            <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              interval={4000}
              ride='carousel'
            >
              {slides}
            </Carousel>
            <div className='carousel-header'>
              <h1 className='carousel-child'>Our mission is to understand and reverse &beta; cell and islet abnormalities and dysfunction in type 1 and type 2 diabetes</h1>
              <div className='carousel-child carousel-footer'>
                <Container>
                  <Row>
                    <Col md='3'>
                      <img alt='' className='carousel-footer-img' src={require('../assets/VUMC-logo-trans.png')} />
                    </Col>
                    <Col className='ml-auto float-right' md='6'>
                      <a href='#contact'><Button color='primary'>Contact Us</Button></a>
                      <span className='or-block'>
                        <span className='or'>or</span>
                      </span>
                      <Link to='/positions'><Button color='danger'>Work With Us</Button></Link>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        )
      } else {
        const { activeIndex } = this.state
        const slides = this.state.carouselImgs.map((item) => {
          return (
            <CarouselItem
              onExiting={this.onExiting}
              onExited={this.onExited}
              key={item.src}
            >
              <div className='carousel-image-outer'>
                <img className='carousel-image-small' src={item.src} alt={item.altText} />
                <div className='overlay' />
              </div>
            </CarouselItem>
          )
        })

        return (
          <div className='carousel-wrapper carousel-small'>
            <Carousel
              activeIndex={activeIndex}
              next={this.next}
              previous={this.previous}
              interval={4000}
              ride='carousel'
            >
              {slides}
            </Carousel>
          </div>
        )
      }
    } else {
      return null
    }
  }
}

export default withRouter(CarouselHeader)
